import React from 'react';
import { products } from 'api/products';
import './GalleryGrid.scss';

interface GalleryGridProps {
  elementRef: React.RefObject<HTMLDivElement>;
}

function GalleryGrid({ elementRef }: GalleryGridProps) {
  let gallery = products.map((product) => {
    let specs =
      product.specs &&
      product.specs.map((spec, idx) => <li key={idx}>{spec}</li>);

    return (
      <figure
        className="gallery-item"
        data-groups={'["' + product.category + '"]'}
        key={product.title}
      >
        <div className="aspect">
          <div className="aspect--inner">
            <img src={product.image} alt={product.title} />
            <div className="details">
              <h4>{product.title}</h4>
              <p>{product.description}</p>
              <ul className="specs">{specs}</ul>
            </div>
          </div>
        </div>
      </figure>
    );
  });

  return (
    <div ref={elementRef} id="gallery-grid">
      {gallery}
      <div className="sizer"></div>
    </div>
  );
}

export default GalleryGrid;

import React from 'react';
import { maintenance, installations } from 'api/services';
import './Services.scss';

interface ServicesProps {
  refs: {
    [key: string]: React.RefObject<HTMLElement>;
  };
}

function Services({ refs }: ServicesProps) {
  let maintenanceItems = maintenance.map((item, idx) => (
    <li key={idx}>{item}</li>
  ));

  let installationItems = installations.map((item, idx) => (
    <li key={idx}>{item}</li>
  ));

  return (
    <section id="services" className="section" ref={refs.services}>
      <div className="container">
        <h2 className="section-title">Services</h2>
        <div className="columns">
          <div className="column is-half">
            <figure className="image">
              <img
                src="/img/service-maintenance.jpg"
                alt="Oil Traps Maintenance Services"
              />
            </figure>
            <p>
              Oil Traps South Africa is one of the most reliable maintenance
              service providers in South Africa. Offering high volume vacuum
              sucking of all size oil traps and sand traps, ensuring that the
              environment is safe and free of contamination.
            </p>
            <p>Services rendered include:</p>
            <ul>{maintenanceItems}</ul>
          </div>
          <div className="column is-half">
            <figure className="image">
              <img
                src="/img/service-installations.jpg"
                alt="Oil Traps Installation Service"
              />
            </figure>
            <p>
              Oil Traps South Africa installs all sizes of oil traps for a
              variety of industries. Our installation team is equipped to ensure
              that each installation receives the best possible work. Each
              client receives a certificate of compliance with local government
              regulation standards.
            </p>
            <p>These include:</p>
            <ul>{installationItems}</ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;

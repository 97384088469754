import React from 'react';
import './Callout.scss';

function Callout() {
  return (
    <section id="callout" className="section">
      <div className="container">
        <div className="columns">
          <div className="column is-half">
            <div className="wrapper">
              <h2>Top Quality</h2>
              <h2 className="highlight">Eco-Friendly</h2>
              <h2>Oil Separators</h2>
            </div>
          </div>
          <div className="column is-half">
            <figure className="image">
              <img
                src="img/environmentally-friendly.png"
                alt="Environmentally Friendly"
              />
            </figure>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Callout;

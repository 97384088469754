import React from 'react';
import Gallery from './components/Gallery';

interface ProductsProps {
  refs: {
    [key: string]: React.RefObject<HTMLElement>;
  };
}

function Products({ refs }: ProductsProps) {
  return (
    <section id="products" className="section" ref={refs.products}>
      <div className="container">
        <h2 className="section-title">Our Products</h2>
        <div className="section-text">
          <p>
            Oil Traps South Africa offers a range of products to fit your needs.
            Custom sized oil traps can be designed to suit any application.
          </p>
        </div>
        <Gallery />
      </div>
    </section>
  );
}

export default Products;

import React, { createRef, useEffect, useState } from 'react';
import Reaptcha from 'reaptcha';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { object, string } from 'yup';
import { encode } from 'helpers/FormEncode';
import { branches } from 'api/branches';
import './ContactForm.scss';

function ContactForm() {
  const [formValues, setFormValues] = useState({});
  const [token, setToken] = useState('');
  const [resetForm, setResetFunc] = useState(Function);
  const [isSubmitting, setSubmitting] = useState(false);
  let recapRef = createRef<Reaptcha>();

  useEffect(() => {
    const handleSubmit = async (formValues: {}, token: string) => {
      const data = {
        ...formValues,
        'form-name': 'Contact',
        'g-recaptcha-response': token,
      };

      try {
        await fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode(data),
        });
        // await new Promise((resolve) => setTimeout(resolve, 3000));
        setSubmitting(false);
        resetForm();
      } catch (err) {
        setSubmitting(false);
      }
    };

    if (token) handleSubmit(formValues, token);
  }, [token]);

  const handleVerify = (token: string) => {
    setToken(token);
  };

  let branchValidator: string[] = [];
  let branchOptions = branches.map((branch, idx) => {
    branchValidator.push(branch.name);
    return (
      <option key={idx} value={branch.name}>
        {branch.name}
      </option>
    );
  });

  return (
    <Formik
      initialValues={{
        name: '',
        email: '',
        phone: '',
        branch: '',
        message: '',
      }}
      validationSchema={object().shape({
        name: string().required('Name is required.'),
        email: string()
          .email('Must be valid email')
          .required('Email is required'),
        phone: string().optional(),
        branch: string()
          .required('Please select a branch')
          .equals(branchValidator, 'Invalid branch selection'),
        message: string().required('Message is required'),
      })}
      onSubmit={(values) => {
        recapRef.current?.execute();
        setSubmitting(true);
        setFormValues({ ...values });
      }}
    >
      {({ resetForm }) => (
        <Form id="contact-form" name="Contact" className="columns is-multiline">
          <Field name="hnypt" type="hidden" />
          <div className="column is-half">
            <Field
              name="name"
              type="text"
              className="input is-medium"
              placeholder="Name"
            />
            <div className="error">
              <ErrorMessage name="name" />
            </div>
          </div>
          <div className="column is-half">
            <Field
              name="email"
              type="email"
              className="input is-medium"
              placeholder="Email"
            />
            <div className="error">
              <ErrorMessage name="email" />
            </div>
          </div>
          <div className="column is-half">
            <Field
              name="phone"
              type="text"
              className="input is-medium"
              placeholder="Phone"
            />
            <div className="error">
              <ErrorMessage name="phone" />
            </div>
          </div>
          <div className="column is-half">
            <div className="select is-medium is-fullwidth">
              <Field name="branch" as="select">
                <option value="" disabled={true}>
                  Select Branch
                </option>
                {branchOptions}
              </Field>
            </div>
            <div className="error">
              <ErrorMessage name="branch" />
            </div>
          </div>
          <div className="column is-full">
            <Field
              name="message"
              as="textarea"
              className="textarea is-medium"
              placeholder="Message..."
            />
            <div className="error">
              <ErrorMessage name="message" />
            </div>
          </div>
          <div className="column is-full">
            <Reaptcha
              sitekey={process.env.REACT_APP_RECAPTCHA_KEY!}
              onLoad={() => setResetFunc(() => resetForm)}
              onVerify={handleVerify}
              onError={() => console.log('error')}
              size="invisible"
              ref={recapRef}
              data-netlify-recaptcha="true"
            />
            <button
              type="submit"
              className={`button is-medium is-oiltraps ${
                isSubmitting ? 'is-loading' : ''
              }`}
              disabled={isSubmitting}
            >
              Send Message
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default ContactForm;

import React from 'react';
import ContactBranches from './components/ContactBranches';
import ContactForm from './components/ContactForm';

interface ContactProps {
  refs: {
    [key: string]: React.RefObject<HTMLElement>;
  };
}

function Contact({ refs }: ContactProps) {
  return (
    <section id="contact" ref={refs.contact}>
      <ContactBranches />
      <section className="section">
        <div className="container">
          <ContactForm />
        </div>
      </section>
    </section>
  );
}

export default Contact;

export const maintenance = [
  'High pressure jet cleaning of all drainage lines',
  'Repairing of pipe lines',
  'High Pressure jet cleaning of oil spills',
  'Safe disposal of used oils',
  'Land oil treatment spills',
  'Dosing pump supply and installation',
  'Effluent discharge testing',
  'Cleaning/Disposal certification',
];

export const installations = [
  'Car wash bays',
  'Panel beaters',
  'Local motors',
  'Car dealerships',
];

import React, { useEffect, useState, createRef } from 'react';
import './ScrollToTop.scss';

interface ScrollToTopProps {
  children: React.ReactNode;
}

function ScrollToTop({ children }: ScrollToTopProps) {
  const [show, setShow] = useState(false);
  let topRef = createRef<HTMLDivElement>();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 600 && !show) setShow(true);
      if (window.scrollY < 600 && show) setShow(false);
    };
    window.addEventListener('scroll', handleScroll);
  }, [show]);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    topRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  return (
    <div id="scroll-to-top" ref={topRef}>
      {show && (
        <button className="to-top-button" onClick={handleClick}></button>
      )}
      {children}
    </div>
  );
}

export default ScrollToTop;

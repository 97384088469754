interface Branch {
  name: string;
  contactPerson: string;
  telephone: string;
  cellphone?: string;
}

export const branches: Branch[] = [
  {
    name: 'Johannesburg',
    contactPerson: 'Eugene Labuschagne',
    telephone: '+27 11 025 6770',
    cellphone: '+27 61 499 0606',
  },
  {
    name: 'Kwa-Zulu Natal',
    contactPerson: 'Wernich Louw',
    telephone: '+31 569 4135',
    cellphone: '+27 82 657 7611',
  },
  {
    name: 'Western Cape',
    contactPerson: 'Jacobus Tromp',
    telephone: '+27 21 827 8955',
    cellphone: '+27 65 913 0595',
  },
  {
    name: 'Rural',
    contactPerson: 'Sean Holland',
    telephone: '+27 73 536 1993',
  },
];

import React, { useState } from 'react';
import { Category } from 'api/products';
import './GalleryFilter.scss';

interface GalleryFilterProps {
  handler: (category: Category | 'all') => void;
}

function GalleryFilter({ handler }: GalleryFilterProps) {
  const [active, setActive] = useState('all');

  const handleClick = (category: Category | 'all') => {
    handler(category);
    setActive(category);
  };

  let filters = Object.values(Category).map((filter) => (
    <li key={filter}>
      <button
        className={active === filter ? 'active' : ''}
        onClick={() => handleClick(filter)}
      >
        {filter}
      </button>
    </li>
  ));

  return (
    <ul id="gallery-filter">
      <li>
        <button
          className={active === 'all' ? 'active' : undefined}
          onClick={() => handleClick('all')}
        >
          All
        </button>
      </li>
      {filters}
    </ul>
  );
}

export default GalleryFilter;

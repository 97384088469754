import React, { useEffect, createRef } from 'react';
import Shuffle from 'shufflejs';
import { Category } from 'api/products';
import GalleryFilter from '../GalleryFilter';
import GalleryGrid from '../GalleryGrid';

function Gallery() {
  let element = createRef<HTMLDivElement>();
  let shuffle = createRef() as React.MutableRefObject<Shuffle>;

  useEffect(() => {
    if (element.current) {
      shuffle.current = new Shuffle(element.current, {
        itemSelector: '.gallery-item',
        sizer: '.sizer',
      });
    }
  }, [shuffle, element]);

  const handleFilter = (category: Category | 'all') => {
    let filter = Shuffle.ALL_ITEMS;
    if (category !== 'all') filter = category;
    shuffle.current.filter(filter);
  };

  return (
    <>
      <GalleryFilter handler={handleFilter} />
      <GalleryGrid elementRef={element} />
    </>
  );
}

export default Gallery;

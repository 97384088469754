import React from 'react';
import { branches } from 'api/branches';
import './ContactBranches.scss';

function ContactBranches() {
  let branchCols = branches.map((branch) => (
    <div className="column is-one-quarter" key={branch.name}>
      <h3>{branch.name}</h3>
      <p>{branch.contactPerson}</p>
      <p>{branch.telephone}</p>
      {branch.cellphone && <p>{branch.cellphone}</p>}
    </div>
  ));

  return (
    <div id="contact-branches">
      <div className="container">
        <div className="columns">{branchCols}</div>
      </div>
    </div>
  );
}

export default ContactBranches;

import React, { useState } from 'react';
import { menuItems } from 'api/menu';
import './Header.scss';

interface HeaderProps {
  refs: {
    [key: string]: React.RefObject<HTMLElement>;
  };
}

function Header({ refs }: HeaderProps) {
  const [showMobileMenu, setMobileMenu] = useState(false);

  const handleClick = (
    e: React.MouseEvent<HTMLAnchorElement>,
    link: string,
  ) => {
    e.preventDefault();
    refs[link].current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
    setMobileMenu(false);
  };

  let menuLinks = menuItems.map((item) => {
    return (
      <li key={item.link} className="navbar-item">
        <a
          href={`#${item.link}`}
          onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
            handleClick(e, item.link)
          }
        >
          {item.title}
        </a>
      </li>
    );
  });

  return (
    <header id="header" className="menu navbar">
      <div className="container">
        <div className="navbar-brand">
          <figure className="image">
            <img src="/img/oiltraps.png" alt="Oil Traps South Africa" />
          </figure>
          <button
            className="navbar-burger burger"
            aria-label="menu"
            onClick={() => setMobileMenu(!showMobileMenu)}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </button>
        </div>
        <nav role="navigation" className="navbar-menu">
          <div className="navbar-end">{menuLinks}</div>
        </nav>
      </div>
      {showMobileMenu && <div className="mobile-menu">{menuLinks}</div>}
    </header>
  );
}

export default Header;

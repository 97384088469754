interface Product {
  image: string;
  title: string;
  description: string;
  specs?: string[];
  category: Category;
}

export enum Category {
  OilTrap = 'Oil Traps',
  SandTrap = 'Sand Traps',
  OilTreatment = 'Oil Treatment',
}

export const products: Product[] = [
  {
    image: 'img/olt1ls.png',
    title: 'OLT 1L/s',
    description: '1 litre per second flow rate. 304/430 Stainless Steel.',
    specs: ['Dimensions: 930 x 600 x 1000mm', '2x lids', '110mm Outlet'],
    category: Category.OilTrap,
  },
  {
    image: 'img/olt3ls.png',
    title: 'OLT 3L/s',
    description: '3 litre per second flow rate. 304/430 Stainless Steel',
    specs: ['Dimensions: 1178 x 895 x 1000mm', '3x lids', '110mm Outlet'],
    category: Category.OilTrap,
  },
  {
    image: 'img/olt5ls.png',
    title: 'OLT 5L/s',
    description: '5 litre per second flow rate. 304/430 Stainless Steel',
    specs: ['Dimensions: 1350 x 1300 x 1170mm', '3x lids', '110mm Outlet'],
    category: Category.OilTrap,
  },
  {
    image: 'img/olt7ls.png',
    title: 'OLT 7L/s',
    description: '7 litre per second flow rate. 304/430 Stainless Steel',
    specs: ['Dimensions: 2100 x 1100 x 1170mm', '4x lids', '110mm Outlet'],
    category: Category.OilTrap,
  },
  {
    image: 'img/olt10ls.png',
    title: 'OLT 10L/s',
    description: '10 litre per second flow rate. 304/430 Stainless Steel',
    specs: ['Dimensions: 2750 x 1450 x 1170mm', '4x lids', '110mm Outlet'],
    category: Category.OilTrap,
  },
  {
    image: 'img/sdt300.png',
    title: 'SDT350',
    description: '304 Stainless Steel Sand Trap. 350 x 350 x 700mm',
    specs: ['Mentis Top Grating', '1x Removable Basket', '110mm Outlet'],
    category: Category.SandTrap,
  },
  {
    image: 'img/sdt500.png',
    title: 'SDT500',
    description: '304 Stainless Steel Sand Trap. 500 x 500 x 700mm',
    specs: ['Mentis Top Grating', '1x Removable Basket', '110mm Outlet'],
    category: Category.SandTrap,
  },
  {
    image: 'img/dosing-pump.png',
    title: 'Dosing Pump',
    description:
      'Dosing pumps are reciprocating positive displacement pumps with a variable flow rate that can be adjusted when the pump is running or stopped, used for the accurate addition of biotechnology products to continuous or batch processes',
    category: Category.OilTreatment,
  },
];

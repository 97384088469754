import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTint,
  faGlobeAfrica,
  faCertificate,
} from '@fortawesome/free-solid-svg-icons';
import './About.scss';

interface AboutProps {
  refs: {
    [key: string]: React.RefObject<HTMLElement>;
  };
}

function About({ refs }: AboutProps) {
  return (
    <section id="about" className="section" ref={refs.about}>
      <div className="container">
        <h2 className="section-title">About Us?</h2>
        <div className="section-text">
          <p>
            Oil Traps South Africa is the industry leader in manufacture and
            maintenance of pretreatment solutions, grease, fuel, oil, and
            solids. We pride ourselves in providing the most affordable and
            effective solutions for your waste water management problems.
          </p>
        </div>
        <div className="columns">
          <div className="column is-one-third">
            <h3 className="title">
              <FontAwesomeIcon icon={faTint} />
              Warranty
            </h3>
            <p>
              All of our products are covered by a 2 year manufacturing warranty
              excluding lids and clips.
            </p>
          </div>
          <div className="column is-one-third">
            <h3 className="title">
              <FontAwesomeIcon icon={faGlobeAfrica} />
              Worldwide Shipping
            </h3>
            <p>
              All of Oil Traps South Africa's products are available to any
              organisation, anywhere in the world.
            </p>
          </div>
          <div className="column is-one-third">
            <h3 className="title">
              <FontAwesomeIcon icon={faCertificate} />
              Certified
            </h3>
            <p>
              All Oil Traps South Africa products are manufactured to industry
              standards.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;

import React, { createRef } from 'react';
import ScrollToTop from 'components/ScrollToTop';

import Header from 'components/Header';
import Hero from './components/Hero';
import Callout from './components/Callout';
import About from './components/About';
import Products from './components/Products';
import Services from './components/Services';
import Contact from './components/Contact';

function App() {
  let refs = {
    about: createRef<HTMLElement>(),
    products: createRef<HTMLElement>(),
    services: createRef<HTMLElement>(),
    contact: createRef<HTMLElement>(),
  };

  return (
    <ScrollToTop>
      <Header refs={refs} />
      <Hero />
      <Callout />
      <About refs={refs} />
      <Products refs={refs} />
      <Services refs={refs} />
      <Contact refs={refs} />
    </ScrollToTop>
  );
}

export default App;
